@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --primary-color: #000000;
  --secondary-color: #ffffff;
  --accent-color: #dc0839;
  --accent-light: #ff1a4f;
  --gray-dark: #333333;
  --gray-light: #4a4a4a;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background: #050404;
}

.app {
  @apply min-h-screen flex flex-col;
}

header {
  @apply bg-primary-500 text-white py-2 px-6;
}

nav ul {
  list-style-type: none;
}

nav ul li a,
nav ul li span {
  @apply text-white hover:text-accent-500 transition-colors;
  text-decoration: none;
}

nav ul li {
  position: relative;
}

nav ul li .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--primary-color);
  padding: 0.5rem;
  border-radius: 3px;
}

nav ul li:hover .dropdown {
  display: block;
}

nav ul li .dropdown li {
  margin-bottom: 0.5rem;
}

nav ul li .dropdown li a {
  color: var(--secondary-color);
  text-decoration: none;
}

nav ul li .dropdown li a:hover {
  text-decoration: underline;
}

main {
  flex: 1;
}

footer {
  @apply bg-primary-500 text-white py-2 px-6 mt-auto w-full;
}

.upload-form {
  background-color: var(--secondary-color);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input[type="file"] {
  @apply bg-gray-800 text-white border border-gray-700 rounded px-3 py-2;
  width: 100%;
}

button {
  @apply bg-accent-500 hover:bg-accent-600 text-white px-4 py-2 rounded transition-colors;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.message {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 3px;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@media (max-width: 768px) {
  header nav ul {
    flex-direction: column;
  }

  header nav ul li {
    margin-bottom: 0.5rem;
  }

  .status-grid {
    grid-template-columns: 1fr;
  }

  .contract-list table {
    font-size: 0.8rem;
  }
}

.status-section {
  margin-bottom: 2rem;
}

.status-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.status-item {
  @apply bg-gray-800 p-4 rounded-lg;
}

.status-active {
  @apply text-accent-500 font-bold;
}

.contract-list table {
  @apply w-full border-collapse;
}

.contract-list th,
.contract-list td {
  border: 1px solid #000;
  padding: 0.5rem;
  text-align: center;
}

.contract-list td a:hover {
  text-decoration: underline;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.social-links a {
  @apply text-white hover:text-accent-500 transition-colors mx-2;
  font-size: 1.5rem;
}

.workflow-diagram {
  margin-top: 2rem;
}

.workflow-steps {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.step {
  background-color: #f0f0f0;
  padding: 0.5rem;
  border-radius: 5px;
}