.documentation {
    display: flex;
    /* height: 100vh; */
    /* background-color: #f3f4f6;
    color: #333; */
    font-family: Arial, sans-serif;
  }
  
  .sidebar {
    width: 250px;
    /* background-color: #ffffff; */
    box-shadow: 2px 0 5px rgba(255, 255, 255, 0.1);
    overflow-y: auto;
    padding: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .sidebar h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    /* color: #2c3e50; */
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    margin-bottom: 0.5rem;
  }
  
  .sidebar button {
    width: 100%;
    text-align: left;
    padding: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  
  .main-content {
    flex: 1;
    overflow-y: auto;
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
  }
  .main-content section {
    max-width: 1100px;
    margin: 0 auto;
  }
  
  .main-content h1 {
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    /* color: #2c3e50; */
    border-bottom: 2px solid #dc0839;
    padding-bottom: 0.5rem;
  }
  
  .main-content h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
    /* color: #34495e; */
  }
  
  .main-content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    /* color: #34495e; */
  }
  
  .main-content p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .main-content ul, .main-content ol {
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
  
  .main-content ul {
    list-style-type: disc;
  }
  
  .main-content ol {
    list-style-type: decimal;
  }
  
  .main-content li {
    margin-bottom: 0.5rem;
  }
  
  .main-content a {
    /* color: #dc0839; */
    text-decoration: none;
  }
  
  .main-content a:hover {
    text-decoration: underline;
  }
  
  .main-content pre {
    /* background-color: #f8f8f8; */
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
  }
  
  .main-content code {
    font-family: 'Courier New', Courier, monospace;
    /* background-color: #f1f1f1; */
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-size: 0.9em;
  }
  
  .main-content blockquote {
    border-left: 4px solid #dc0839;
    padding-left: 1rem;
    margin-left: 0;
    /* color: #7f8c8d; */
  }
  
  .main-content table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    overflow-x: auto;
    display: block;
  }
  
  .main-content th, .main-content td {
    /* border: 1px solid #e0e0e0; */
    padding: 0.5rem;
    text-align: left;
  }
  
  .main-content th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .main-content img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .main-content hr {
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 2rem 0;
  }
  
  .menu-toggle {
    display: none;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 1000;
    background: #dc0839;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .documentation {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      transform: translateX(-100%);
      z-index: 1000;
    }
  
    .sidebar.active {
      transform: translateX(0);
    }
  
    .main-content {
      padding: 1rem;
      margin-top: 3rem;
    }
  
    .menu-toggle {
      display: block;
    }
  
    .main-content h1 {
      font-size: 2rem;
    }
  
    .main-content h2 {
      font-size: 1.5rem;
    }
  
    .main-content h3 {
      font-size: 1.25rem;
    }
  }
  
  @media (max-width: 480px) {
    .main-content {
      padding: 0.5rem;
    }
  
    .main-content h1 {
      font-size: 1.75rem;
    }
  
    .main-content h2 {
      font-size: 1.35rem;
    }
  
    .main-content h3 {
      font-size: 1.15rem;
    }
  }
